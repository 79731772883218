<template>
	<div class="p-grid" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Button :disabled ="$store.state.loading" :label="$t('Nuevo')" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
					<template v-slot:right>
					</template>
				</Toolbar>
				<DataTable :value="products" :paginator="false" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">{{$t('Tipo de Tarifa')}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="name" header="Name" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="valor" :header="$t('Valor')" :sortable="true">
						<template #body="slotProps">
							{{moneda(slotProps.data.valor)}}
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit"/>
							<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Tipo de Tarifa')" :modal="true" :maximizable="true" class="p-fluid">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-4">
								<label for="nombre">Nombre</label>
								<InputText id="nombre" v-model.trim="product.nombre" required="true"/>
							</div>
							<div class="p-col-4">
								<label for="name">Name</label>
								<InputText id="name" v-model.trim="product.name" required="true"/>
							</div>
							<div class="p-col-4">
								<label for="name">{{$t('Valor')}}</label>
								<InputText v-model="product.valor" type="number" min="0" style="width:100%;"/>
							</div>
							<div class="p-col-4">
								{{$t('Catálogo')}} WA
							</div>	
							<div class="p-col-8">
								<label for="name">{{$t('Tipo de Vehículo')}}</label>
								<Dropdown id="state" v-model="tipo" :options="tipovehiculo" :optionLabel="$t('nombre')" placeholder="?..."></Dropdown>
							</div>	
							<div class="p-col-12" v-if="tipo">
								<DataTable :value="product.opciones.filter(val => val.tipovehiculo==tipo.id)" responsiveLayout="scroll">
									<Column :header="$t('Intervalo')">
										<template #body="slotProps">
											{{moneda(slotProps.data.min)+' - '+moneda(slotProps.data.max)}}
										</template>
									</Column>
									<Column field="monto" :header="$t('Monto')" headerStyle="width: 25%">
										<template #body="slotProps">
											<InputText v-model="slotProps.data.monto" type="number" min="0" style="max-width:150px;"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirm" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button :disabled ="$store.state.loading" label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button :disabled ="$store.state.loading" label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','ValorCatalogo');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				permisosDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				permisos: {},
				selectedProducts: null,
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				niveles: null,
				tipo: null,
				tipovehiculo: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.permisos = null;
				this.products = null;
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.valores;
					this.tipovehiculo = result.consult.tipovehiculo;
				}); 
			},
			openNew() {
				this.product = {
					nombre: null,
					name: null,
					opciones: []
				};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				this.productDialog = false;
				var caso = 'INS';
				if (this.product.id) {
					caso = 'UPD';
				}
				Consulta.Procesar(caso,{
					product: this.product
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.productDialog = false;
				this.product = {};
			},
			editProduct(product) {
				this.tipo = this.tipovehiculo[0];
				this.product = {...product};
				this.productDialog = true;
			},
			editPermiso(permiso) {
				this.permisos = {...permiso};
				this.permisosDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.Mostrar();
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			moneda(number){
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
